import myRequest from '../http/index.js'
// 个人中心页
export function userinfoAPI(params){
    return myRequest({
        url:"index.php/index/Userinfo/Userinfo",         
        params,
        method:"get"
      })
}
// 我的订单
export function orderListAPI(params){
    return myRequest({
        url:"index.php/index/Userinfo/orderList",         
        params,
        method:"post"
      })
}
// 订单详情
export function orderViewAPI(params){
    return myRequest({
        url:"index.php/index/Userinfo/orderView",         
        params,
        method:"get"
      })
}
// 确认开票
export function upBillingAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userAddressBillingAdd",         
      params,
      method:"get"
    })
}
// 开票信息
export function BillingInfoAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/BillingInformation",         
      params,
      method:"get"
    })
}
// 订单评论
export function orderCommentAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/orderCommentTrue",         
      params,
      method:"post"
    })
}

// 个人资料
export function userViewAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/canceUser",         
      params,
      method:"get"
    })
}
// 修改个人资料
export function userEditAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/cancelRequest",         
      params,
      method:"post"
    })
}
// 索要发票 （发票列表）（常用地址） 
export function addressBillinglistAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userAddressBilling",         
      params,
      method:"get"
    })
}

// 删除常用地址
export function addressdelAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userAddressDel",         
      params,
      method:"get"
    })
}
// 添加常用地址
export function addressAddAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userAddress",         
      params,
      method:"post"
    })
}
// 修改常用地址
export function addressEditAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userAddressUpdate",         
      params,
      method:"get"
    })
}
// 添加发票
export function addBillingAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userBilling",         
      params,
      method:"post"
    })
}
// 更新发票
export function userBillingUpdate(params){
  return myRequest({
      url:"index.php/index/Userinfo/userBillingUpdate",         
      params,
      method:"post"
    })
}
// del发票
export function delBillingAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userBillingDel",         
      params,
      method:"post"
    })
}

// 问答记录
export function userQuestionlistAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userQuestionAnswerList",         
      params,
      method:"post"
    })
}
// 常用出行人list
export function useroutdoorlistAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userContactList",         
      params,
      method:"get"
    })
}
// add+edit常用出行人
export function adduseroutdoorAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userContactUpdateAdd",         
      params,
      method:"post"
    })
}

// del常用出行人
export function deluseroutdoorAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userContactUpdateDel",         
      params,
      method:"get"
    })
}
// 个人中心-+体验卡
export function orderMemberListAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/orderMemberList",         
      params,
      method:"get"
    })
}
// 购买体验卡页
export function buyCardviewAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/systemMemberCard",         
      params,
      method:"get"
    })
}

// 我的游记
export function userArticleListAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userArticleTakeList",         
      params,
      method:"get"
    })
}
// 删除我的游记
export function userArticleDelAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userArticleTakeDel",         
      params,
      method:"get"
    })
}
//add我的游记
export function userArticleAddAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userArticleTakeAddUpdate",         
      params,
      method:"post"
    })
}


// 订单消息通知
export function ordermsgListAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/messagePushLogList",         
      params,
      method:"get"
    })
}
// 我的收藏--游记
export function collectionListAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/collectionTake",         
      params,
      method:"get"
    })
}
// 接口：取消收藏
export function collectionTakeDelAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/collectionTakeDel",         
      params,
      method:"get"
    })
}
// 奖励积分
export function userBillListAPI(params){
  return myRequest({
      url:"index.php/index/userinfo/userBillList",         
      params,
      method:"get"
    })
}
// 我的定制
export function mycustomListAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/TeamList",         
      params,
      method:"get"
    })
}
// 我的定制
export function orderDel(params){
  return myRequest({
      url:"index.php/index/task/orderDel",         
      params,
      method:"post"
    })
}
// 我的定制
export function userCouponList(params){
  return myRequest({
      url:"index.php/index/Userinfo/userCouponList",         
      params,
      method:"post"
    })
}
// ：我的活动收藏
export function collectionGoods(params){
  return myRequest({
      url:"index.php/index/Userinfo/collectionGoods",         
      params,
      method:"get"
    })
}

// 核查身份证
export function CheckIdAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/CheckId",         
      params,
      method:"get"
    })
}
// 我的点评
export function userEvaluationListAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userEvaluationList",         
      params,
      method:"get"
    })
}
// 消息删除
export function messagePushLogDeleteAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/messagePushLogDelete",         
      params,
      method:"get"
    })
}
// 消息未读数
export function messageCountAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/messageCount",         
      params,
      method:"get"
    })
}
//  读数
export function messageReadAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/messageRead",         
      params,
      method:"get"
    })
}