<template>
	<div class="user">
		<h4>我的等级积分</h4>
		<el-table
		    :data="tableData"
		    border
		    style="width: 100%;background-color: #eaeaea;">
		    <el-table-column
		      prop="add_time"
		      label="积分日期"
		     >
		    </el-table-column>
			<el-table-column
		      prop="goods_name"
		      label="报名活动名称"
		      >
		    </el-table-column>
		    <el-table-column
		      prop="title"
		      label="来源"
		      >
		    </el-table-column>
		    <el-table-column  prop="address" label="积分数">
			  <template slot-scope="scope">
				  <p style="color: #FF7C00;" v-if="scope.row.pm==1">+{{scope.row.number}}</p>
				  <p v-else>-{{scope.row.number}}</p>
			  </template>
		    </el-table-column>
		  </el-table>

		  <div class="page">
			  <el-pagination
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange"
				:page-size="pageSize">
				</el-pagination>
		  </div>
	</div>
</template>

<script>
import {userBillListAPI} from "@/api/user"
export default {
	    data () {
	      return {
	        tableData: [],
			total:1,
			page:1,
			pageSize:10
	      }
	    },
		created(){
			this.getlist()
		},
		methods:{
			handleCurrentChange(currentPage){
				this.page=currentPage
				this.getlist()
			},
			getlist(){
				var data={
					page:this.page,
					pageSize:this.pageSize,
					type:2
				}
				userBillListAPI(data).then(res=>{
					this.tableData=res.cateList
					this.total=res.rowsCount
				})
			}
		}
	  }
</script>

<style lang="scss">
	.user{
		.el-table__header-wrapper .el-table__header{
			background-color: #00FFFF !important;
		}
		padding: 5px 20px 20px;
		background: #ffffff;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}
	}
	.page{
		display: flex;
		margin-top: 20px;
		justify-content: flex-end;
	}
</style>
